import React, { useEffect } from 'react'
import { useState } from 'react';
import { Col, Container, Offcanvas, Row } from 'react-bootstrap';
import Sidenav from '../../Componet/Sidenav/Sidenav';
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import * as XLSX from "xlsx/xlsx";
import './Milkwise.css'

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import moment from 'moment';
import { downloadExcel } from 'react-export-table-to-excel';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import Searcable from '../../Componet/Dropdown/Searcable';
import "react-toastify/dist/ReactToastify.css";
import BackBtn from '../../Componet/Button/BackBtn';
const { DoctorApi, MilkTestApi, BulkMilkApi } = API_URLS;


const Bulkwisemilk = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    // const [shouldRefresh, setShouldRefresh] = useState(false);

    // useEffect(() => {
    //     if (shouldRefresh) {
    //         viewFn();
    //         setShouldRefresh(false); 
    //     }
    // }, [shouldRefresh]);


    async function getZone(credentials) {
        return fetch(DoctorApi.getZone, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function getAllData(credentials) {
        return fetch(MilkTestApi.getAll, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getViewData(credentials) {
        return fetch(BulkMilkApi.viewApi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getVisitRoute(credentials) {
        return fetch(Animal_Zone.GetVisitRoute, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${added_by}`
            },
            body: JSON.stringify(credentials)
        }).then(res => res.json())
    }

    const API_KEY = serviceUrl;
    const selectedToDate = new Date().toISOString().slice(0, 10);
    const [center, setCenter] = useState("");
    const [ait, setAit] = useState("");
    const [sort, setSort] = useState("")
    const [order, setOrder] = useState("")
    const [society, setSociety] = useState("")
    const [farmer, setFarmer] = useState("")
    const [route, setRoute] = useState("")
    const [search, setSearch] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const [searchFilter, setSearchFilter] = useState("")
    const [mor, setMor] = useState("")
    const [eve, setEve] = useState("")
    const [night, setNight] = useState("")
    const [midnight, setMidnight] = useState("")
    const [daysTotal, setDaysTotal] = useState("")
    const [fat, setFat] = useState("")
    const [snf, setSnf] = useState("")
    const [lactose, setLactose] = useState("")
    const [parity, setParity] = useState("")
    const [centerId, setCenterId] = useState(true)
    const [routeId, setRouteId] = useState(true)
    const [societyId, setSocietyId] = useState(true)
    const [tagId, setTagId] = useState("")
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }
    const [fileName, setFileName] = useState('Import');
    const [loaderImport, setLoaderImport] = useState("");
    const [excelData, setExcelData] = useState([])
    const [excelColumOpen, setExcelColumOpen] = useState(false)
    const [importFile, setImportFile] = useState(false)
    const [date, setDate] = useState(selectedToDate);

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [doctorList, setDoctorList] = useState([])
    const [centerList, setCenterList] = useState([]);
    const [trackingList, setTrackingList] = useState([])
    const [routeList, setRouteList] = useState([])
    const [farmerList, setFarmerList] = useState([])
    const [societyList, setSocietyList] = useState([])
    const [milkList, setMilkList] = useState([])
    const [excelColumData, setExcelColumData] = useState([])


    const getDoctorData = async () => {
        const res = await getZone()
        if (res) { setCenterList(res) }
    }

    const fatchData = async (e, e1, e2) => {
        if (e) {
            console.log("Aa")
            fetch(`${API_KEY}/cattle-detail/getAllVisitRoutes`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${added_by}`
                }
            })
                .then(res => res.json())
                .then(result => {
                    setRouteList(result)
                    console.log("bb")

                })
        }

        if (route) {
            // console.log(route);
            fetch(`${API_KEY}/cattle-detail/getVisitLot/${route.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setSocietyList(result); })
        }
        if (society) {
            // console.log(society);
            fetch(`${API_KEY}/cattle-detail/getVisitFarmer/${society.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setFarmerList(result); })
        }
        // const res1 = await getAllData()
        const res2 = await getViewData()

        // if (res1) { setMilkList(e.data) }
        if (res2) { setMilkList(e?.data) }

    }

    const fatchAnimalData = async () => {

    }

    const selectCenter = async (e) => {
        setCenter(e);
        fatchData(e)
    }

    const selectSociety = (e2) => {
        setSociety(e2);
        fatchData(e2)
    }

    const selectRoute = (e1) => {
        setRoute(e1);
        fatchData(e1)
    }

    useEffect(() => {
        getDoctorData()
        fatchData()
    }, [route, society])

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = milkList.filter((user) => {
                return user.TagId.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(milkList);
        }
        setSearch(data);
    }


    const textHandle = (e, index) => {
        const value = e.target.value;
        setMilkList(milkList.map((o) => {
            if (o === index) { return { ...index, Mor: value } }
            else { return o };
        }));
    }
    const textHandle0 = (e, index) => {
        const value = e.target.value;
        setMilkList(milkList.map((o) => {
            if (o === index) { return { ...index, Eve: value } }
            else { return o };
        }));
    }
    const textHandle1 = (e, index) => {
        const value = e.target.value;
        setMilkList(milkList.map((o) => {
            if (o === index) { return { ...index, Night: value } }
            else { return o };
        }));
    }
    const textHandle2 = (e, index) => {
        const value = e.target.value;
        setMilkList(milkList.map((o) => {
            if (o === index) { return { ...index, Midnight: value } }
            else { return o };
        }));
    }
    const textHandle3 = (e, index) => {
        const value = e.target.value;
        setMilkList(milkList.map((o) => {
            if (o === index) { return { ...index, ["Days Total"]: value } }
            else { return o };
        }));
    }
    const textHandle4 = (e, index) => {
        const value = e.target.value;
        setMilkList(milkList.map((o) => {
            if (o === index) { return { ...index, FAT: value } }
            else { return o };
        }));
    }
    const textHandle5 = (e, index) => {
        const value = e.target.value;
        setMilkList(milkList.map((o) => {
            if (o === index) { return { ...index, SNF: value } }
            else { return o };
        }));
    }
    const textHandle6 = (e, index) => {
        const value = e.target.value;
        setMilkList(milkList.map((o) => {
            if (o === index) { return { ...index, Lactose: value } }
            else { return o };
        }));
    }

    // const saveFn = async (e) => {
    //     console.log(e)
    //     await fetch(`${API_KEY}/v1/api/web/milk/save-milk-entry`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             "Accept": "application/json",
    //             "Authorization": `Bearer ${added_by}`
    //         },
    //         body: JSON.stringify({ eveningYield: e.Eve, fat: fat, inputDate: date, lactose: lactose, midnightYield: midnight, morningYield: mor, nightYield: night, officialMilk: 1, parity: e["lac No"] ? e["lac No"] : 0, protein: "", snf: e.SNF, tagId: e.TagId, createdAt: selectedToDate })
    //     })
    //         .then(res => res.json())
    //         .then((result) => { fatchData(); })
    // }

    const saveFn = async (e) => {

        await fetch(`${API_KEY}/production/milk/SaveBulkMilkEntry`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ eveningYield: e.Eve, fat: fat, inputDate: date, lactose: lactose, midnightYield: midnight, morningYield: mor, nightYield: night, officialMilk: 1, parity: e["lac No"] ? e["lac No"] : 0, protein: "", snf: e.SNF, tagId: e.TagId, createdAt: selectedToDate })
        })
            .then(res => res.json())
            .then((result) => {
                // fatchData(); 
            })
    }

    const viewFn = async () => {
        // console.log(excelColumData)
        // console.log(importFile)
        setIsLoading(true)
        setFileName("Import")
        // if (importFile === false)
        {
            // console.log("No")
            await fetch(BulkMilkApi.viewApi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ copCom: center ? `${center.value}` : null, dcs: society ? `${society.value}` : null, farmer: farmer ? `${farmer.value}` : null, inputDate: date, route: route ? `${route.value}` : null })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((result) => {
                    setMilkList(result);
                    setIsLoading(false);
                    setProgressData("0%");

                })
        }
        // else if (importFile === true){  
        //     setIsLoading(false);
        //     return;
        // }
        // else 
        // {
        //     console.log("janvi taw ");
        //     let filteredList = [...milkList]; // Copy the milkList

        //     for (let i = 0; i < excelData.length; i++) 
        //     {
        //         var Tagid = excelData[i].Tagid;
        //         var Mor = excelData[i].Mor;
        //         var Eve = excelData[i].Eve;

        //         for (let j = 0; j < filteredList.length; j++) {
        //             // Check if the current record's TagId matches the TagId from excelData
        //             if (filteredList[j].TagId === Tagid.toString()) {
        //                 // Update Mor and Eve properties for the matched item
        //                 filteredList[j].Mor = Mor;
        //                 filteredList[j].Eve = Eve;
        //                 console.log("Updated Mor for Tag id " + Tagid + ": " + filteredList[j].Mor);
        //             }
        //         }

        //         // filteredList = filteredMilk;
        //     }
        //     setMilkList(filteredList);
        //     setIsLoading(false); 
        //     setProgressData("0%");

        //     // for (let index = 0; index < excelColumData[0].length; index++) {
        //     //     const e = excelColumData[0][index];
        //     //     if (e.Mor !== undefined || e.Eve !== undefined) {
        //     //         setIsLoading(true)
        //     //         // await fetch(`${API_KEY}/v1/api/web/milk/save-milk-entry`, {
        //     //         await fetch(`${API_KEY}/production/milk/SaveBulkMilkEntry`, {
        //     //             method: 'POST',
        //     //             headers: {
        //     //                 'Content-Type': 'application/json',
        //     //                 "Accept": "application/json",
        //     //                 "Authorization": `Bearer ${added_by}`
        //     //             },
        //     //             body: JSON.stringify({
        //     //                 eveningYield: e.Eve ? e.Eve : null,
        //     //                 fat: fat,
        //     //                 inputDate: date,
        //     //                 lactose: lactose,
        //     //                 midnightYield: midnight,
        //     //                 morningYield: e.Mor ? e.Mor : null,
        //     //                 nightYield: night,
        //     //                 officialMilk: 1,
        //     //                 parity: e["lactation No"] ? e["lactation No"] : 0,
        //     //                 protein: "",
        //     //                 snf: e.SNF ? e.SNF : "",
        //     //                 tagId: e.TagId ? e.TagId.toString() : e.Tagid.toString(),
        //     //                 createdAt: selectedToDate
        //     //             })

        //     //         })
        //     //             .then(response => {
        //     //                 const contentLength = response.headers.get('content-length');
        //     //                 let loaded = 0;
        //     //                 return new Response(
        //     //                     new ReadableStream({
        //     //                         start(controller) {
        //     //                             const reader = response.body.getReader();
        //     //                             read();
        //     //                             function read() {
        //     //                                 reader.read()
        //     //                                     .then((progressEvent) => {
        //     //                                         if (progressEvent.done) {
        //     //                                             controller.close();
        //     //                                             return;
        //     //                                         }
        //     //                                         loaded += progressEvent.value.byteLength;
        //     //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
        //     //                                         setProgressData(percentageComplete)
        //     //                                         controller.enqueue(progressEvent.value);
        //     //                                         read();
        //     //                                     })
        //     //                             }
        //     //                         }
        //     //                     })
        //     //                 );
        //     //             })
        //     //             .then(res => res.json())
        //     //             .then((result) => {
        //     //                 if (index === excelColumData[0].length - 1) {

        //     //                 } fatchData(); setIsLoading(false); setImportFile(false); setProgressData("0%");
        //     //                 toast.success("Import File Save successfully");
        //     //             })
        //     //     }
        //     // }
        // }

    }




    const tableKeyDownHandler = (e) => {
        if (e.target.tagName === 'INPUT' && e.keyCode === 40) { // Check for down arrow key
            const inputField = e.target;
            const cell = inputField.closest('.rdt_TableCell');
            const row = cell.closest('.rdt_TableRow');
            const nextRow = row.nextElementSibling;
            if (nextRow) {
                const columnIndex = Array.from(row.children).indexOf(cell);
                const nextCell = nextRow.children[columnIndex];
                if (nextCell) {
                    const nextInputField = nextCell.querySelector('input');
                    if (nextInputField) {
                        nextInputField.focus();
                    }
                }
            }
        }
    };

    const tableKeyUpHandler = (e) => {
        if (e.target.tagName === 'INPUT' && e.keyCode === 38) { // Check for up arrow key
            const inputField = e.target;
            const cell = inputField.closest('.rdt_TableCell');
            const row = cell.closest('.rdt_TableRow');
            const prevRow = row.previousElementSibling;
            if (prevRow) {
                const columnIndex = Array.from(row.children).indexOf(cell);
                const prevCell = prevRow.children[columnIndex];
                if (prevCell) {
                    const prevInputField = prevCell.querySelector('input');
                    if (prevInputField) {
                        prevInputField.focus();
                    }
                }
            }
        }
    };

    const table = document.querySelector('.rdt_Table');

    table?.addEventListener('keydown', tableKeyDownHandler);
    table?.addEventListener('keyup', tableKeyUpHandler);

    const colum = [
        {
            // do not change the name cause of export time problems
            name: "TagId",
            selector: "TagId",
            cell: (e) => <div className='ms-auto' style={{ paddingRight: "5px" }}>{e["TagId"]}</div>,
            width: "135px",
            sortable: true
        },
        {
            name: `Animal Name`,
            selector: "Animal Name",
            cell: (e) => <div className='text-right px-1' style={{ paddingRight: "5px" }}>{e["Animal Name"]}</div>,
            width: "90px",
            sortable: true
        },
        {
            name: "lac No",
            selector: "lac No",
            cell: (e) => <div className='ms-auto' style={{ paddingRight: "5px" }}>{e["lac No"]}</div>,
            width: "50px",
            sortable: true
        },
        {
            name: "Calving Date",
            selector: "Calving Date",
            cell: (e) => <div className='ms-auto' style={{ paddingRight: "5px" }}>{moment(e["Calving Date"]).format("DD-MM-YYYY")}</div>,
            width: "120px",
            sortable: true
        },
        {
            name: "Milk Days",
            selector: "Milk Days",
            cell: (e) => <div className='ms-auto' style={{ paddingRight: "5px" }}>{e["Milk Days"]}</div>,
            width: "70px",
            sortable: true
        },
        {
            name: "Last Milk(kg)",
            selector: "Last milk(kg)",
            cell: (e) => <div className='ms-auto' style={{ paddingRight: "5px" }}>{e["Last milk(kg)"]}</div>,
            width: "79px",
            sortable: true
        },
        {
            name: "Last Recording Date",
            selector: "Last Recording Date",
            cell: (e) => (
                <div className='ms-auto' style={{ paddingRight: "5px" }}>
                    {e["Last Recording Date"] ? e["Last Recording Date"] : ""}
                </div>
            ),

            width: "120px",
            sortable: true
        },
        {
            name: "Mor",
            selectore: "Mor",
            cell: (d, i) => <input type='text' className="inpField1 ms-auto text-end" value={d.Mor ? d.Mor : ""} onChange={(e) => textHandle(e, d)} onKeyUp={(e) => tableKeyUpHandler(e, i, 'Mor')} onKeyDown={(e) => tableKeyDownHandler(e, i, 'Mor')} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Eve",
            selectore: "Eve",
            cell: (d, i) => <input type='text' className="inpField1 ms-auto text-end" value={d.Eve ? d.Eve : ""} onChange={(e) => textHandle0(e, d)} onKeyUp={(e) => tableKeyUpHandler(e, i, 'Eve')} onKeyDown={(e) => tableKeyDownHandler(e, i, 'Eve')} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Night",
            selectore: "Night",
            cell: (d, i) => <input type='text' className="inpField1 ms-auto text-end" value={d.Night ? d.Night : ""} onChange={(e) => textHandle1(e, d)}
                onKeyUp={(e) => tableKeyUpHandler(e, i, 'Night')} onKeyDown={(e) => tableKeyDownHandler(e, i, 'Night')} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Mid night",
            selectore: "Midnight",
            cell: (d, i) => <input type='text' className="inpField1 ms-auto ms-auto me-0 text-end" value={d.Midnight ? d.Midnight : ""} onChange={(e) => textHandle2(e, d)} onKeyUp={(e) => tableKeyUpHandler(e, i, 'Mid night')} onKeyDown={(e) => tableKeyDownHandler(e, i, 'Mid night')} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Days Total",
            selectore: "Days Total",
            cell: (d, i) => {
                const total = ((parseFloat(d.Mor) || 0) + (parseFloat(d.Eve) || 0) + (parseFloat(d.Night) || 0) + (parseFloat(d.Midnight) || 0)).toString();
                return <input type='text' className="inpField1 ms-auto ms-auto me-0 text-end" value={total !== "0" ? total : ""} onChange={(e) => textHandle3(e, d)} onKeyUp={(e) => tableKeyUpHandler(e, i, 'Days Total')} onKeyDown={(e) => tableKeyDownHandler(e, i, 'Days Total')} />;
            },
            width: "70px",
            sortable: true
        },
        {
            name: "FAT",
            selectore: "FAT",
            cell: (d, i) => <input type='text' className="inpField1 ms-auto me-0 text-end" value={d.FAT ? d.FAT : ""} onChange={(e) => textHandle4(e, d)} onKeyUp={(e) => tableKeyUpHandler(e, i, 'FAT')} onKeyDown={(e) => tableKeyDownHandler(e, i, 'FAT')} />,
            width: "70px",
            sortable: true
        },
        {
            name: "SNF",
            selectore: "SNF",
            cell: (d, i) => <input type='text' className="inpField1 ms-auto me-0 text-end" value={d.SNF ? d.SNF : ""} onChange={(e) => textHandle5(e, d)} onKeyUp={(e) => tableKeyUpHandler(e, i, 'SNF')} onKeyDown={(e) => tableKeyDownHandler(e, i, 'SNF')} />,
            width: "70px",
            sortable: true
        },
        {
            name: "Lactose",
            selectore: "Lactose",
            cell: (d, i) => <input type='text' className="inpField1 ms-auto me-0 text-end" value={d.Lactose ? d.Lactose : ""} onChange={(e) => textHandle6(e, d)} onKeyUp={(e) => tableKeyUpHandler(e, i, 'Lactose')} onKeyDown={(e) => tableKeyDownHandler(e, i, 'Lactose')} />,
            width: "90px",
            sortable: true
        },
        {
            name: "Actions",
            cell: (e) => (<><button className='saveMilk me-0' onClick={() => saveFn(e)}>Save</button></>),
            width: "220px"
        },
    ]

    const excelColum = [
        {
            name: "TagId",
            selector: "TagId",

        },
        {
            name: "mor",
            selector: "mor",

        },
        {
            name: "eve",
            selector: "eve",

        }
    ]

    const handleDownloadExcel = () => {
        const header = []
        const opt = `BulkWise MilkEntry ${date}`;
        colum.forEach((e) => {
            // console.log("e : " + JSON.stringify(e))
            header.push(e.name)
        })

        console.log("rowData : " + JSON.stringify(milkList))

        const body = milkList.map((item) => {
            const rowData = {};
            header.forEach((key) => {
                rowData[key] = item[key] || "";
                // console.log("rowData[key] : " + JSON.stringify(item[key]))

            });
            return rowData;
        });

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header: header,
                body: body
            }
        });

    }

    // const handleFileChange = (e) => {
    //     console.log(e.target.files[0])
    //     setImportFile(true)
    //     const file = e.target.files[0];
    //     setFileName(file ? (file.name).slice(0, 7) : 'Choose File');
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //         console.log(event)
    //         const data = new Uint8Array(event.target.result);
    //         const workbook = XLSX.read(data, { type: 'array' });
    //         console.log(workbook)
    //         const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //         const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    //         console.log(jsonData)

    //         // const excelData = [];
    //         // for (let index = 0; index < jsonData.length; index++) {
    //         //     const element = jsonData[index];
    //         //     // console.log(element)
    //         //     excelData.push({ "TagId": element[0].toString(), "Mor": element[1], "Eve": element[2] } || { "TagId": element[0].toString(), "Mor": element[7], "Eve": element[8] })
    //         // }
    //         // setExcelColumOpen(true)
    //         // // milkList.push(excelData)

    //         // // console.log(excelData)


    //         // excelColumData.push(excelData)
    //     };
    //     reader.readAsArrayBuffer(file);
    // };

    // const handleFileChange = (e) => {
    //     console.log(e.target.files[0])
    //     setImportFile(true)
    //     const file = e.target.files[0];
    //     setFileName(file ? (file.name).slice(0, 7) : 'Import');
    //     setLoaderImport(file.name)
    //     const reader = new FileReader();

    //     reader.onload = (event) => {
    //         const data = new Uint8Array(event.target.result);
    //         const workbook = XLSX.read(data, { type: 'array' });
    //         const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //         const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    //         // console.log(jsonData)

    //         if (jsonData.length > 1) {
    //             const headers = jsonData[0];
    //             const excelData = jsonData.slice(1).map(row => {
    //                 return headers.reduce((acc, curr, index) => {
    //                     acc[curr] = row[index];
    //                     return acc;
    //                 }, {});
    //             });

    //             // const isValid = excelData.every(obj => (
    //             //     obj.hasOwnProperty('TagId') && obj.hasOwnProperty('Mor') && obj.hasOwnProperty('Eve')
    //             // ));
    //             const isValid = excelData.every(obj => (
    //                 obj.hasOwnProperty('TagId') || obj.hasOwnProperty('tagid') || obj.hasOwnProperty('Tagid') &&
    //                 obj.hasOwnProperty('Mor') || obj.hasOwnProperty('mor') &&
    //                 obj.hasOwnProperty('Eve') || obj.hasOwnProperty('eve')
    //             ));

    //             console.log(excelData)

    //             if (isValid) {
    //                 excelColumData.push(excelData);
    //                 // excelColumData.push(excelData)
    //             } else {
    //                 setImportFile(false)
    //                 setFileName("Import")
    //             }

    //         }
    //         toast.error('Some objects do not contain required keys (TagId, Mor, Eve)');
    //     };

    //     reader.readAsArrayBuffer(file);
    // };



    // const SaveFn = async () => {
    //     if (milkList.length > 0) {
    //         setIsLoading(true)

    //         milkList.forEach(async (e) => {
    //             if ((e.Mor !== null) || (e.Eve !== null) || (e.Night !== null && e.Night.toString() !== "0") || (e.Midnight !== null && e.Midnight.toString() !== "0") || (e.FAT !== null && e.FAT.toString() !== "0") ||
    //                 (e.SNF !== null && e.SNF.toString() !== "0") ||
    //                 (e.Lactose !== null && e.Lactose.toString() !== "0")
    //             ) {
    //                 // await fetch(`${API_KEY}/v1/api/web/milk/save-milk-entry`, {
    //                 await fetch(`${API_KEY}/production/milk/SaveBulkMilkEntry`, {
    //                     method: 'POST',
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                         "Accept": "application/json",
    //                         "Authorization": `Bearer ${added_by}`
    //                     },
    //                     body: JSON.stringify({ eveningYield: e.Eve, fat: e.FAT, inputDate: date, lactose: e.Lactose, midnightYield: e.Midnight, morningYield: e.Mor, nightYield: e.Night, officialMilk: 1, parity: e["lac No"] ? e["lac No"] : 0, protein: "", snf: e.SNF, tagId: e.TagId, createdAt: selectedToDate })
    //                 })
    //                     .then(response => {
    //                         const contentLength = response.headers.get('content-length');
    //                         let loaded = 0;
    //                         return new Response(
    //                             new ReadableStream({
    //                                 start(controller) {
    //                                     const reader = response.body.getReader();
    //                                     read();
    //                                     function read() {
    //                                         reader.read()
    //                                             .then((progressEvent) => {
    //                                                 if (progressEvent.done) {
    //                                                     controller.close();
    //                                                     return;
    //                                                 }
    //                                                 loaded += progressEvent.value.byteLength;
    //                                                 const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                                 setProgressData(percentageComplete)
    //                                                 controller.enqueue(progressEvent.value);
    //                                                 read();
    //                                             })
    //                                     }
    //                                 }
    //                             })
    //                         );
    //                     })
    //                     .then(res => res.json())
    //                     .then((result) => { fatchData(); setIsLoading(false) })
    //                 // toast.success("Save Data Successful");
    //             }
    //             else {
    //                 setIsLoading(false)
    //             }
    //         })
    //     }
    //     else {
    //         setIsLoading(false)
    //     }
    // }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImportFile(true);

        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            if (jsonData.length > 1) {
                const headers = jsonData[0].map(header => header.trim()); // Remove spaces from headers
                const excelData = jsonData.slice(1).map(row => {
                    return headers.reduce((acc, curr, index) => {
                        acc[curr] = row[index];
                        return acc;
                    }, {});
                });

                // const isValid = excelData.every(obj => (
                //     obj.hasOwnProperty('TagId') &&
                //     obj.hasOwnProperty('Mor') &&
                //     obj.hasOwnProperty('Eve')
                // ));

                // if (isValid) {
                //     // Update project data with Excel data
                //     setMilkList(excelData);
                // } else {
                //     // toast.error('Some objects do not contain required keys (TagId, mor, eve)');
                // }
                // milkList
                // console.log(excelData);
                setExcelData(excelData);
                console.log("Parsed Excel Data:", excelData)
                let filteredList = [...milkList]; // Copy the milkList
                var Night = "";
                for (let i = 0; i < excelData.length; i++) {
                    var Tagid = excelData[i].Tagid;
                    var Mor = excelData[i].Mor;
                    var Eve = excelData[i].Eve;
                    try {
                        Night = excelData[i].Night;
                    } catch (ex) {

                    }

                    for (let j = 0; j < filteredList.length; j++) {
                        if (filteredList[j].TagId === Tagid.toString()) {
                            filteredList[j].Mor = Mor;
                            filteredList[j].Eve = Eve;
                            if (Night !== undefined) {
                                filteredList[j].Night = Night;
                            }

                            console.log("Updated Mor for Tag id " + Tagid + ": " + filteredList[j].Mor);
                        }
                    }
                    // Update the filteredList to reflect the changes
                    // filteredList = filteredMilk;
                }

                // Update the state of milkList with the filtered and updated list
                setMilkList(filteredList);
                console.log("filter", filteredList);

            } else {
                toast.error('Invalid file format or empty file');
            }

        };
        reader.readAsArrayBuffer(file);
    };

    const userId = localStorage.getItem("USERID")

    const SaveFn = async () => {
        if (milkList.length > 0) {
            setIsLoading(true);

            const bulkData = [];

            milkList.forEach((e) => {
                if ((e.Mor !== null) || (e.Eve !== null) || (e.Night !== null && e.Night.toString() !== "0") || (e.Midnight !== null && e.Midnight.toString() !== "0") || (e.FAT !== null && e.FAT.toString() !== "0") ||
                    (e.SNF !== null && e.SNF.toString() !== "0") ||
                    (e.Lactose !== null && e.Lactose.toString() !== "0")
                ) {
                    bulkData.push({
                        Tagid: e.TagId,
                        Parity: e["lac No"] ? e["lac No"] : 0,
                        MorningYield: e.Mor,
                        EveningYield: e.Eve,
                        NightYield: e.Night,
                        MidnightYield: e.Midnight,
                        Fat: e.FAT,
                        Snf: e.SNF,
                        Lactose: e.Lactose,
                        Protein: "",
                        LactationMilkTotal: 1
                    });
                }
            });

            if (bulkData.length > 0) {
                const payload = {
                    jsonData: JSON.stringify(bulkData),
                    Date: date,
                    createdByUser: parseInt(userId)
                };

                await fetch(`${API_KEY}/production/milk/SaveBulkMilkEntry`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Accept": "application/json",
                        "Authorization": `Bearer ${added_by}`
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => {
                        return response.json();
                    })
                    .then((result) => {
                        fatchData();
                        setIsLoading(false);
                        if (result.Data == 1) {
                            toast.success("Data Inserted Successfully..!!")
                        } else {
                            toast.error("Failed to insert data..!!")
                        }
                    })
                    .catch(error => {
                        setIsLoading(false);
                        console.error('Error:', error);
                    });
            } else {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Milk Entry /Bulk Wise MilkEntry</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end div-2" id='heightOffsetStock'>
                                <Col xxl="1" xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Center</InputLabel>
                                        <Select value={center} onChange={(e) => selectCenter(e.target.value)}>
                                            {centerList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Center"
                                            options={centerList}
                                            onChange={(selectedOption) => selectCenter(selectedOption)}
                                            value={center}
                                            labelKey="Name"
                                            valueKey="id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xxl="1" xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Route</InputLabel>
                                        <Select value={route} onChange={e => setRoute(e.target.value)}>
                                            {routeList.map((e) => (
                                                <MenuItem className="drop_value" key={e.Id} value={e.Id}>
                                                    {e.name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Route"
                                            options={routeList}
                                            onChange={(selectedOption) => setRoute(selectedOption)}
                                            value={route}
                                            labelKey="name"
                                            valueKey="Id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xxl="1" xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Society</InputLabel>
                                        <Select value={society} onChange={e => setSociety(e.target.value)}>
                                            {societyList.map((e) => (
                                                <MenuItem className="drop_value" key={e.Id} value={e.Id}>
                                                    {e.name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Society"
                                            options={societyList}
                                            onChange={(selectedOption) => setSociety(selectedOption)}
                                            value={society}
                                            labelKey="name"
                                            valueKey="Id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xxl="1" xs="6" sm="2" md="2" className='justify-content-start'>
                                    <FormControl variant="standard" fullWidth>
                                        {/* <InputLabel>Farmer</InputLabel>
                                        <Select value={farmer} onChange={e => setFarmer(e.target.value)}>
                                            {farmerList.map((e) => (
                                                <MenuItem className="drop_value" key={e.Id} value={e.Id}>
                                                    {e.name}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                        <Searcable
                                            placeholder="Farmer"
                                            options={farmerList}
                                            onChange={(selectedOption) => setFarmer(selectedOption)}
                                            value={farmer}
                                            labelKey="name"
                                            valueKey="Id"
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs="6" xxl="1" sm="3" md="2" >
                                    <sub className="mb-0 pb-0">Date</sub>
                                    <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                </Col>
                                <Col md="3" xxl="2" xl="2" xs="6" className='align-items-center ms-auto'>
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                                <Col xxl="1" md="1">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={viewFn}>View</button>
                                </Col>
                                <Col xxl="1" md="1">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                                </Col>
                                <Col xxl="1" md="1">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={() => { SaveFn(); }}>Save</button>
                                </Col>
                                <Col xxl="1" md="1">
                                    {/* <button className="btn-blue-Animal mt-1 mb-1" onClick={handleFileChange}>Import</button> */}
                                    <button className='btn-blue-Animal mt-1 mb-1'>
                                        <label className="file-input-label mb-0" >
                                            {fileName}
                                            <input
                                                type="file"
                                                accept=".xlsx"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                        </label>
                                    </button>
                                </Col>
                            </Row>
                            <Row className='mt-2 text-end me-2'>
                                <Col>
                                    <h6>Total: {milkList?.length}</h6>
                                </Col>
                            </Row>
                            {/* <Row className='justify-content-center text-center'>
                                <Col className="">
                                    <Table columns={colum} data={searchFilter ? searchFilter : milkList} height={`calc(100vh - (${heightOffSet}px + 215px))`} pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {milkList?.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : milkList} height={`calc(100vh - (${heightOffSet}px + 210px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col key={i.name} className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
                limit="1"
            />
        </>
    )
}

export default Bulkwisemilk